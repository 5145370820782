<template>
<div>
<Header></Header>
    <!--顶部banner开始-->
    <div class="aboutus-banner left" style="margin-bottom:20px;"> <img src="~images/banner/shehuizeren.jpg" width="100%" /> </div>

    <!--内容开始-->
    <div class="news-content left clearfix">
           <div class="news-contents center">

              <!--面包屑开始-->
                <Breadcrumb>
                <a href="/#/index" title="首页"> <BreadcrumbItem>首页</BreadcrumbItem> </a>
                <a href="/#/social_responsibility" title="社会责任"> <BreadcrumbItem>社会责任</BreadcrumbItem> </a>
                <BreadcrumbItem>文章列表</BreadcrumbItem>
                </Breadcrumb>

                <div style="height:20px;"></div>

               <a :href="'/#/social_responsibility_detail?social='+item.id" :title="item.title" v-for="item in list" :key="item.id">
              <div class="news-contents-li left clearfix">
                 <!--左边开始-->
                 <div class="news-contents-li-left left clearfix">
                    <h2>{{item.title}}</h2>
                    <p>{{item.content}}</p>
                     <p style="color:#999;">发表于：{{item.add_time}}</p>
                 </div>
                 <div class="news-contents-li-right right"> <img :src="item.img_path" width="100%" /> </div>
              </div>
              </a>
              
               <div class="page-box left clearfix"> <Page :total="total" @on-change="getNewsList" :page-size="pageSize"/> </div>
           </div>
        </div>
        <div class="left clearfix width-bai"> <Footer></Footer> </div>
        <contact-call></contact-call>
</div>
</template>
<script>
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
export default {
   name:"",
   data() {
      return {
          list:'',
         total:''
      }
   },
   components:{
       ContactCall,
       Header,
       Footer
   },
   mounted() {
     this.getNewsList(1);
  },
  methods: {
     getNewsList(p){
        var typeData=this.$route.query.type;
         this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_sociology_list.html?token='+this.$store.state.token+'&type='+typeData+'&page='+p).then(res=>{
                     this.list=res.data.result;
                     this.total=res.data.total;
                  });
         }
  },
}
</script>

<style scoped>
.page-box{width:100%;line-height:80px;text-align:center;}
.news-contents-li-right{width:300px;height:200px;margin:15px;overflow:hidden;margin-right:50px;margin-top:20px;}
.news-contents-li-right img{transition: all 2s;cursor:pointer;}
.news-contents-li-right img:hover{transform: scale(1.2);}
.news-contents-li-left p{line-height:30px;padding:20px;font-size:15px;}
.news-contents-li-left h2{line-height:40px;margin-top:20px;padding-left:20px;font-size:16px;font-weight:600;}
.news-contents-li-left{width:1000px;height:240px;}
.news-contents-li{width:1440px;height:240px;margin-top:30px;margin-bottom:20px;box-shadow: 0px 2px 5px #cccccc;cursor:pointer;}
.news-contents-li:hover{box-shadow: 0px 2px 5px rgb(242,151,0);}
.news-title-content{height:35px;margin:auto;border-radius:5px;width:405px;}
.news-title-content ul li{float:left;width:100px;line-height:35px;background:rgb(242,151,0);color:#fff;text-align:center;border-right:1px solid #fff;}
.news-title{width:100%;height:50px;margin-top:50px;margin-bottom:40px;}
.news-contents{width:1440px;min-height:800px;}
.news-content{width:100%;min-height:800px;}
.aboutus-banner{width:100%;height:470px;}
</style>
